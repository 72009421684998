var scrollTo = function(){
  this.init();
}

scrollTo.prototype = {

  init: function(){
    var $this = this;
    if(!this._setVars()) return;
    this._setEvents();
  },

  _setVars: function(){
    this._scrollBtn = $('.jsScrollToTop');
    this._scrollToSection = $('.jsScrollToSection');
    this._section = $('.contact-section');

    return true;
  },

  _showBtn: function(){
    this._scrollBtn.css("display", "flex");
  },

  _hideBtn: function(){
    this._scrollBtn.css("display", "none");
  },

  _scrollToThis: function(){
    var $this = this;

    $('html, body').animate({
      scrollTop: $this._section.offset().top
    }, 2000);
  },

  _setEvents: function(){
    var $this = this;

    /* on scroll show or hide btn to scrollTop */
    $(window).scroll(function(){
  		if ($(this).scrollTop() > 600) {
  			$this._showBtn();
  		}
      else {
  			$this._hideBtn();
  		}
	  });

    /* scroll to top */
    this._scrollBtn.on('click', function(){
      $('html, body').animate({scrollTop : 0},800);
		    return false;
    });

    /* scroll to map section */
    this._scrollToSection.on('click', function(){
      $this._scrollToThis();
    });
  }
}
