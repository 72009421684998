var poll = function(){
	this.init();
};

poll.prototype = {

	init: function() {

		var $this = this;

		this._setEvents();

	},

	_sendForm: function(form) {

		var error   = 0;
		var form_id = form.attr('id');

		form.find('.row').each(function() {

			if ((($('.radio', this).length > 0) && ($('input:checked', this).val() === undefined)) || (($('input[type=text]', this).length > 0) && ($('input[type=text]', this).val().length == 0))) {

				error = 1;

			}

		});
		
		form.find('.wpcf7-response-output').slideUp();


		if ($.cookie(form_id) == 1) {

			form.find('.wpcf7-response-output[info-type=error-cookie]').slideDown();

		} else if (error) {

			form.find('.wpcf7-response-output[info-type=error-form]').slideDown();

		} else {

			$.ajax({
				type: 'POST',
				url: $('#' + form_id).attr('action'),
				data: $('#' + form_id).serialize(),
				success: function(status) {
					
					if (status == 1) {

						$.cookie(form_id, 1, {expires: 365, path: '/'});
						form.find('.wpcf7-response-output[info-type=send-ok]').slideDown();

					} else {

						form.find('.wpcf7-response-output[info-type=send-error]').slideDown();

					}

				}
			});

		}

	},

	_setEvents: function() {

		var $this = this;

		$('.poll input[type=submit]').click(function(event) {

			event.preventDefault();

			$this._sendForm($(this).closest('form'));

		});

	}

}