var calculator = function(){

	this.init();
};

calculator.prototype = {

	init: function(){

		var $this = this;

		if (!this._setVars())
			return;

		this._setEvents();
	},

	_setVars: function(){

		this.firstSlider = $(".calc__value");
		this.secondSlider = $(".calc__time");

		this.firstSliderInput = $('.calc__value--number input');
		this.secondSliderInput = $('.calc__time--number input');

		/* max min values */
		this.firstSliderMin = $('.calc__value').data('min');
		this.firstSliderMax = $('.calc__value').data('max');

		this.secondSliderMin = $('.calc__time').data('min');
		this.secondSliderMax = $('.calc__time').data('max');

		/* starting value */
		this.firstSliderStart = $('.calc__value').data('start');
		this.secondSliderStart = $('.calc__time').data('start');

		/* step value */
		this.firstSliderStep = $('.calc__value').data('step');
		this.secondSliderStep = $('.calc__time').data('step');

		this.firstSliderValue = $(".calc__value");
		this.secondSliderValue = $(".calc__time");

		/* percent value */
		this.percentValue = $('.investment__info').data("percent") / 100;

		/* helpful variables */
		this.ileRazy = 0;
		this.potega = 0;
		this.profit = 1500;
		this.secondCalcMax = this.secondSliderMax;

		/* select var */
		this.select = $('.calc__select > div');

		/* calc type */
		this._calcType = $('.calc').data("type");
		this._calcType2 = $('.calc').data("type2");

		return true;
	},

	_setUpSlider: function(item, itemInput, min, max, step, startingValue){
		var $this = this;
		item.slider({
      value: startingValue,
      orientation: "horizontal",
      range: "min",
      animate: true,
			min: min,
			step: step,
			max: max,
			change: function(){
				itemInput.val($(this).slider("value"));
				$this._switchCalc($this._calcType);
			},
			slide: function(){
				itemInput.val($(this).slider("value"));
				$this._switchCalc($this._calcType);
			}
    });
	},

	_setInputValueOnStart: function(item, slider){
			item.val(slider.slider("value"));
	},

	_changeSlideOnInput: function(item, slider){
		value = item.val();

		slider.slider("value", value);
	},

	_numberWithCommas: function(item) {
    item = item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	},

	_calculateValue1: function(){
		var $this = this;

		this.firstSliderValue = $this.firstSlider.slider("value");
		this.secondSliderValue = $this.secondSlider.slider("value");

		/* power of */
		this.power = ( 1 + (this.percentValue / 12) );
		this.powerNew = this.power;

		this.ileRazy = this.secondSliderValue;

		for(i = 2; i <= this.ileRazy; i++){
			this.powerNew = this.powerNew * this.power;
		}

		this.powerNew = 1 / this.powerNew;

		this.profit = ( (this.firstSliderValue * (this.percentValue / 12) ) / (1 - this.powerNew) );

		this.profit = Math.round(this.profit * 100) / 100;
		this.profit = this.profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

		var htmlContent = '<span class="calc-result">Wysokość raty:<span><span>'+ this.profit +'</span> zł</span></span>';

		$('.jsTablePlace').html(htmlContent);

	},

	_calculateValue2: function(){
		var $this = this;

		this.firstSliderValue = $this.firstSlider.slider("value");
		this.secondSliderValue = $this.secondSlider.slider("value");

		var days = this.secondSliderValue * 30;
		var totalDays = this.secondCalcMax;
		totalDays = totalDays * 30;

		this.profit = ( $this.firstSliderValue * days / 365);

		this.profit = this.profit * this.percentValue;
		this.profit = this.profit * 0.81;
		this.profit = Math.round(this.profit * 100) / 100;
		this.profit = this.profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

		$('.calc-result > span > span').html(this.profit);
	},

	_calculateValue3: function(){

		var $this = this;

		this.firstSliderValue = $this.firstSlider.slider("value");
		this.secondSliderValue = $this.secondSlider.slider("value");

		var value = this.firstSliderValue;
		var numberOfInstalment = this.secondSliderValue;

		var sn = value / numberOfInstalment;

		var instalment = [];

		var n = numberOfInstalment;

		for(var n = 1; n <= numberOfInstalment; n++){

			var i = sn * [1 + (numberOfInstalment - n + 1) * ($this.percentValue / 12)];
			instalment.push(i);

		}

		var htmlContent = '';

		var $index = 1;
		$.each(instalment, function(key, value){

			value = Math.round(value * 100) / 100;
			value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			htmlContent += '<tr class="even"><td class="left">'+$index+'</td><td class="left">'+value+' zł</td></tr>';
			$index++;
		});

		htmlContent = '<table><tbody><tr><th>Numer raty</th><th>Kwota raty</th></tr>'+htmlContent+'</tbody></table>';

		$('.jsTablePlace').html(htmlContent);
	},

	_switchCalc: function(item){
		var $this = this;

		if(item == "kredyt"){
			
			if($('.calc__select .active').data('type2') == 'kredytZwy'){
				$this._calculateValue1();
			}
			else if($('.calc__select .active').data('type2') == 'kredytMalej'){
				$this._calculateValue3();
			}

		}
		else if(item == "lokata"){
			$this._calculateValue2();
		}
	},

	_updateSliderValue: function(item){
		var $this = this;

		$('.investment__info span').text($productsArray[item][1])
		this.percentValue = $productsArray[item][1] / 100;

		this.firstSlider.slider("destroy");

		this._setUpSlider($this.firstSlider, $this.firstSliderInput, parseFloat($productsArray[item][4]), parseFloat ($productsArray[item][3]), parseFloat($productsArray[item][5]), parseFloat($productsArray[item][6]));

		this._setInputValueOnStart($this.firstSliderInput, $this.firstSlider);

		this.secondSlider.slider("destroy");

		this._setUpSlider($this.secondSlider, $this.secondSliderInput, parseFloat($productsArray[item][9]), parseFloat ($productsArray[item][8]), parseFloat($productsArray[item][10]), parseFloat($productsArray[item][11]));

		this.secondCalcMax = $productsArray[item][8];
		this._setInputValueOnStart($this.secondSliderInput, $this.secondSlider);

	},

	_selectProduct: function(item){
		var currentSlider = item.data("value");

		this._updateSliderValue(currentSlider);
	},

	_togglerCalcOption: function(item){
		var $this = this;

		item.toggleClass("active");

		if(item.hasClass("active") && $this.select.length > 3 && item.hasClass("calc__select")){
			item.toggleClass("scrollable");
		}
		if(item.hasClass("calc__select") && $this.select.length == 2){
			item.toggleClass("active--two");
		}
	},

	_setEvents: function(){
		var $this = this;

		/* 1st slider */
		this._setUpSlider($this.firstSlider, $this.firstSliderInput, $this.firstSliderMin, $this.firstSliderMax, $this.firstSliderStep, $this.firstSliderStart);
		this._setInputValueOnStart($this.firstSliderInput, $this.firstSlider);
		$this.firstSliderInput.on('change', function(){
			$this._switchCalc($this._calcType);
				$this._changeSlideOnInput($(this), $this.firstSlider);
		});

		/* 2nd slider */
		this._setUpSlider($this.secondSlider, $this.secondSliderInput, $this.secondSliderMin, $this.secondSliderMax, $this.secondSliderStep, $this.secondSliderStart);
		this._setInputValueOnStart($this.secondSliderInput, $this.secondSlider);
		$this.secondSliderInput.on('change', function(){
			$this._switchCalc($this._calcType);
				$this._changeSlideOnInput($(this), $this.secondSlider);
		});

		$('.calc__select').on('click', function(){
			if($this.select.length > 1){
				$this._togglerCalcOption($(this));
				$('.jsOnClickHide').toggleClass("active");
			}
		});

		this.select.on('click', function(){
			if($(this).hasClass("active")){

			}
			else{
				$this.select.removeClass("active");
				$this._togglerCalcOption($(this));
				$this._selectProduct($(this));
			}
		});

		$('.jsOnClickHide').on('click', function(){
				$this._togglerCalcOption($('.calc__select'));
				$this._togglerCalcOption($(this));
		})

	}
}
