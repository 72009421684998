var contactMap = function(){
	this.init();
};

contactMap.prototype = {

	init: function() {

		if (!$('.contactMap').length)
			return;

		this._setEvents();

	},

	_setVars: function() {
		var $this = this;

		this.gmarkers = [];

		return true;

	},

	_initMap: function() {
		var $this = this;

		this.map = new google.maps.Map(document.getElementById('map'), {
			zoom: 13,
			center: {lat: $('#map').data('lat'), lng: $('#map').data('lng')},
			styles:
			[{"featureType": "landscape.man_made","elementType":"all","stylers":[{"color":"#faf5ed"},{"lightness":"0"},{"gamma":"1"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#bae5a6"}]},{"featureType":"road","elementType":"all","stylers":[{"weight":"1.00"},{"gamma":"1.8"},{"saturation":"0"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"hue":"#ffb200"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"lightness":"0"},{"gamma":"1"}]},{"featureType":"transit.station.airport","elementType":"all","stylers":[{"hue":"#b000ff"},{"saturation":"23"},{"lightness":"-4"},{"gamma":"0.80"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#a0daf2"}]},{"featureType": "poi","elementType": "labels", "stylers": [{"visibility": "off"}]}],
			mapTypeControl: false,
			streetViewControl: false,
			scrollwheel: false
		});
		
		this._addMarker();

	},

	_addMarker: function() {

		var $this = this;

		var marker = new google.maps.Marker({
			position: {lat: $('#map').data('lat'), lng: $('#map').data('lng')},
			title: 'SGB-Bank S.A.',
			icon: '../wp-content/themes/sgb/images/map/marker_hover.png'
		});

		marker.setMap($this.map);

	},

	_setEvents: function() {

		var $this = this;

		$(document).ready(function() {

			$this._initMap();

		});

	}

}