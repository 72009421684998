var exchangesBoxV2 = function(){

	this.init();

};

exchangesBoxV2.prototype = {

	init: function(){

		var $this = this;

		if (!this._setVars())
			return;

		this._setEvents();

	},

	_setVars: function() {

		var $this = this;

		$this._exchangesBox      = $('.exchangesBox');
		if(!$this._exchangesBox.length) return false;
		$this._exchangesCarousel = $this._exchangesBox.find('.owl-carousel');
		$this._carouselInit      = false;

		return true;

	},

	_initOwlCarousel: function() {

		var $this = this;

		$this._exchangesCarousel.owlCarousel({
			loop: true,
			dots: false,
      autoplay: true,
      autoplayTimeout: 5000,
			responsive : {
				0    : {
					items: 1
				},
				400  : {
					items: 2
				},
				600  : {
					items: 3
				},
				768  : {
					items: 4
				},
				1024 : {
					items: 5
				}
			}
		});

		$this._carouselInit = true;

	},

	_counterStart: function() {

		var $this = this;

		$this._exchangesCarousel.find('.counter__number span').each(function() {

			$(this).prop('Counter', 0).animate({
				Counter: $(this).attr('data-value')
			}, {
				duration: 1000,
				easing: 'linear',
				step: function(now) {

					$(this).text(now.toFixed(4));
				}
			});

		});

	},

	_setEvents: function() {

		var $this = this;

		$this._initOwlCarousel();

		$this._exchangesBox.find('.jsCounter--right').click(function() {

			$this._exchangesCarousel.trigger('next.owl.carousel');

		});

		$this._exchangesBox.find('.jsCounter--left').click(function() {

			$this._exchangesCarousel.trigger('prev.owl.carousel');

		});

		$(window).scroll(function() {

			if ($this._carouselInit) {

				if (($('body').scrollTop() + $(window).outerHeight()) >= ($this._exchangesBox.offset().top + $this._exchangesBox.outerHeight())) {

					$this._counterStart();

				}

			}

		});

	}

};
