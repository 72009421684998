var counter = function(){
    this.init();

};

counter.prototype = {
    init: function() {

        if(!this._setVars()) return;
        this._setEvents();
    },

    _setVars: function() {

        var $this = this;

        this._counter = $('.counter');
        if(!$this._counter.length) return false;

        this._counterNumber = this._counter.find('.counter__value');

        this._drawLine = this._counter.find('.counter__line');

        // helper
        this._earlierExchangeValue;
        this._exchangeArchiveClass = '';

        return true;
    },

    _counterStart: function(item, i) {

        item.each(function(){

            $(this).prop('Counter', 0).animate({
                Counter: $(this).attr('data-value')
            }, {
                duration: 1000,
                easing: 'linear',
                step: function(now) {
                    if(i === 1){
                        $(this).text(Math.floor(now));
                    }
                    else{
                        $(this).text(now.toFixed(4));
                    }
                }
            });
        });


    },

    _setupCarousell2: function(){
        var aboutUs = $('.jsCounterTop');

        aboutUs.owlCarousel({
            loop: true,
            dots: false,
            responsive:{
                0:{
                    items: 1
                },
                450:{
                    items: 2
                },
                650:{
                    items: 3
                },
                1024:{
                    items: 4,
                    touchDrag  : false,
                    mouseDrag  : false
                }
            }
        });

        $('.jsCarousellTop--right').click(function() {
            aboutUs.trigger('next.owl.carousel');
        })
        $('.jsCarousellTop--left').click(function() {
            aboutUs.trigger('prev.owl.carousel');
        })

    },

    _drawLiner: function() {
        this._drawLine.css("transform", "scale(1)");
    },

    _setEvents: function(){
        var $this = this;

        this._setupCarousell2();
        this._drawLiner();
        this._counterStart($this._counterNumber, 1);

    }

};
