var validationContact = function() {
	this.init();
};

validationContact.prototype = {
	init: function() {
		var $this = this;
		if (!this._setVars()) return;
		this._setEvents();
	},

	_setVars: function() {
		this._form = $(".contact");
		if (!this._form.length) return false;
		this._submit = this._form.find('input[type="submit"]');

		// some strange behaviour of contact form acceptance checkboxes fix
		this._submit.prop('disabled', false);


		this._checkboxes = this._form.find('input[type="checkbox"]');


		//this._submit = this._form.find("input[type='button']");
		//this._accept = this._form.find('.acceptance p input[type="checkbox"]').length;
		this._output = this._form.find(".acceptance p:last-of-type");

		return true;
	},

	_validate: function() {
		var $this = this;

		//$this._submit.prop('disabled', false);

		//console.log('here 1')

		if (this._form.find('.acceptance p input[name="acceptance-dane2"]').length) { // konto za 1zl
			if (this._form.find('.acceptance p input[name="acceptance-dane"]:checked').length &&
				this._form.find('.acceptance p input[name="acceptance-dane2"]:checked').length &&
				this._form.find('.acceptance p input[name="acceptance-prawa"]:checked').length) {
				console.log('valid2');
				return true;
			} else {
				console.log('not valid2');
				return false;
			}
		} else { // regular form
			if (this._form.find('.acceptance p input[name="acceptance-dane"]:checked').length &&
				this._form.find('.acceptance p input[name="acceptance-prawa"]:checked').length) {
				console.log('valid1');
				return true;
			} else {
				console.log('not valid1');
				return false;
			}
		}

	},

	_setEvents: function() {
		var $this = this;

		// some strange CF7 submit-disable-behaviour fix
		$('.wpcf7-acceptance').each(function() {
			$(this).removeClass('wpcf7-acceptance');
		});

		// some strange behaviour of contact form acceptance checkboxes fix
		this._checkboxes.on("click", function() {
			$this._submit.prop('disabled', false);
		});

		this._submit.on("click", function() {
			$this._output.css("display", "none");
			if (!$this._validate()) {
				$this._output.text(
					"W związku z brakiem zgód nie mogą Państwo przesłać zapytania. Prosimy o kontakt telefoniczny z Bankiem bądź odwiedzić najbliższy oddział."
				);
				$this._output.css("display", "block");

				return false;
			// } else {
			// 	console.log('submit');
			// 	$this._form.submit();
			}
		});
	}
};
