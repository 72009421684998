var slider = function(){
    this.init();
}

slider.prototype = {
    init: function () {
      var $this = this;
      if(!this._setVars()) return;
      this._setEvents();
   },

    _setVars: function() {
      this._slider = $('.slider');
      this._slide = this._slider.find('.slider__thumbs__item');
      this._sliderContetn_holder = this._slider.find('.slider__content');
      this._sliderContent = this._slider.find('.slider__content div');

      this._goToLeft = this._slider.find('.slider__nav__left');
      this._goToRight = this._slider.find('.slider__nav__right');

      /* slide content variables */
      this._slider__head = $('.slider__text__1')
      this._slider__text = $('.slider__text__2')
      this._slider__url = $('.slider__text a');

      /* time */
      this._timeout;
      this._slideDurationTime = 7000;

      /* helpfull variables */
      this._currentBcg = "";
      this._currentHead = "";
      this._currentText = "";
      this._currentUrl = "";

      this._activeSlide = 1;

      this._time = 200;
      this._firstRun = 1;

      return true;
    },

    _setSlideContentValues: function(item){
      var $this = this;

      this._currentBcg = item.data("img");
      this._currentHead = item.data("head");
      this._currentText = item.data("text");
      this._currentUrl = item.data("url");

      this._toggleActiveSlide(item);
    },

    _changeSlide: function(){
      var $this = this;
      var content;

      clearTimeout(content);
      $this._time = 250;

      if($this._firstRun == 1){
        $this._time = 0;
      }
      else{
        $this._time = 250;
      }

      this._toggleClass($this._sliderContetn_holder, "active");
      $this._toggleClass($this._slider__head, "jsAnimate");
      $this._toggleClass($this._slider__text, "jsAnimate");

      content = setTimeout(function(){
        $this._sliderContent.css("background-image", "url("+$this._currentBcg+")");
        $this._slider__head.text($this._currentHead);
        $this._slider__text.text($this._currentText);

        $this._toggleClass($this._sliderContetn_holder, "active");
        $this._toggleClass($this._slider__head, "jsAnimate");
        $this._toggleClass($this._slider__text, "jsAnimate");

      }, $this._time);

      this._firstRun = 2;

      this._slider__url.attr("href", this._currentUrl);
      this._startSlider();

    },

    _toggleClass: function(item, className){
      item.toggleClass(className);
    },

    _checkWhichSlideIsNext: function(whichWay){
      var $this = this;

      if(whichWay == "left"){
        $this._activeSlide--;
        if($this._activeSlide < 1){
          $this._activeSlide = 3;
        }
      }

      if(whichWay == "right"){
        $this._activeSlide++
        if($this._activeSlide > 3){
          $this._activeSlide = 1;
        }
      }

      var nextSlide = $('.slider__thumbs .slider__thumbs__item:nth-of-type('+$this._activeSlide+')');

      this._setSlideContentValues(nextSlide);
      this._changeSlide();
    },

    _startSlider: function(i){
       var $this = this;

       if(i == 1){
          $this._firstRun = 1;
       }

       clearTimeout($this._timeout);
       $this._timeout = setTimeout(function() {
          $this._checkWhichSlideIsNext("right");
       }, $this._slideDurationTime);
   },

   _toggleActiveSlide: function(item){

     this._slide.removeClass("active");
     item.toggleClass("active");

   },

    _setEvents: function($index){
      var $this = this;

      this._checkWhichSlideIsNext("default");

      this._startSlider($this._firstRun);

      this._goToLeft.on('click', function(){
          $this._checkWhichSlideIsNext("left");
      });

      this._goToRight.on('click', function(){
          $this._checkWhichSlideIsNext("right");
      });

      this._slide.on('click', function(){
        $this._activeSlide = $(this).data("count");

        nextSlide = $('.slider__thumbs .slider__thumbs__item:nth-of-type('+$this._activeSlide+')');

        $this._setSlideContentValues(nextSlide);
        $this._changeSlide();
      })
    }
}
