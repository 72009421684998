var slideHeader = function(){
	this.init();
};

slideHeader.prototype= {

	init: function(){
    var $this = this;
    if(!this._setVars()) return;
    this._setEvents();
  },

	_setVars: function(){
		this._header = $('header.wrapper--wider');
		this._topHeaderHeight = $('header > nav:first-of-type').height();
		this._headerHeight = this._header.height();

		if($('.fsBox__products')) return false;
		
		return true;
	},

	_setEvents: function(){
		var $this = this;

		$(window).scroll(function(){

			if($('html').height() > 1015 && $('html').height() < 1060) {

				return false;

			}

			else {

				var top_of_object = $this._header.offset().top;
	      var bottom_of_window = $(window).scrollTop() + 450;

	      if ($(window).scrollTop() > 50) {
	          $this._header.addClass('fixed');
	          $('body').css("margin-top", $this._headerHeight);
	          $('.single-site').css("margin-top", $this._headerHeight);
	      }
	      else if ($(window).scrollTop() < 50) {
	          $this._header.removeClass('fixed');
	          $('body').css("margin-top", 0);
	          $('.single-site').css("margin-top", 0);
	      }

      }

		});
	}

}
