var filter = function(){this.init();};

filter.prototype = {
  init: function(){
    var $this = this;
    if(!this._setVars()) return;
    this._setEvents();
  },

  _setVars: function() {

  },
}
