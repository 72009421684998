var ajaxPostLoader = function(){

	this.init();

};

ajaxPostLoader.prototype = {

	init: function(){

		var $this = this;

		if (!this._setVars())
			return;

		this._setEvents();

	},

	_setVars: function(){

		this._wrap = $('.news--site__content');
		this._current_page = 1;
		this._max_page = this._wrap.attr('data-max-pages');

		return true;

	},

	_setEvents: function(){

		var $this = this;

		$('.btn', $this._wrap).click(function() {

			$this._current_page++;

			if ($this._current_page >= $this._max_page) {

				$('.btn', $this._wrap).hide();

			}

			$.ajax({
				url: 'page/' + $this._current_page + '/' + window.location.search
			})
			.done(function(list) {

				$(list).insertBefore($('.btn', $this._wrap));
				$('.container-box', $this._wrap).addClass('visible');

			});

		});

	}

}
