var sLoginMobile = function(){

    this.init();

};

sLoginMobile.prototype = {

    init: function(){

        var $this = this;
        if (!this._setVars())
            return;
        this._setEvents();

    },

    _setVars: function(){

        var $this = this;

        this._mobilePanel = $('.mPanel');
        if(!$this._mobilePanel) return false;

        this._mobileChecker = this._mobilePanel.find('[data-item="loginDropdown"]');
        if($this._mobileChecker.length == 0) return false;

        this._loginSwitch = this._mobilePanel.find('.login>a:first-of-type');
        this._loginOption = this._mobilePanel.find('.login>a:not(:first-of-type)');

        return true;

    },

    _toggleClass: function(){

        this._loginOption.toggleClass('visible');
    },

    _setEvents: function(){

        var $this = this;

        this._loginSwitch.on('click', function(e){

            e.preventDefault();
            $this._toggleClass();

        });

        $(document).mouseup(function (e){

            var container = $this._mobilePanel;

            if(!container.is(e.target) && container.has(e.target).length === 0){

                if(!$this._loginOption.hasClass('visible')){
                    return;
                }
                else{
                    $this._toggleClass();
                }

            };

        });
    }

}
