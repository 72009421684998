var offerScroll = function(){
  this.init();
}

offerScroll.prototype = {

	init: function(){
    var $this = this;
    if(!this._setVars()) return;
    this._setEvents();
  },

	_setVars: function(){
    this._scrollBtn = $('.jsScroller');
    this._scrollSection = $('.jsScrollToMe');

    return true;
  },

	_scrollToSection: function(){
		var $this = this;

		$('html, body').animate({
			scrollTop: $this._scrollSection.offset().top - 250
		}, 2000);
	},

	_setEvents: function(){
		var $this = this;

		this._scrollBtn.on('click', function(){
			$this._scrollToSection();
		});
	}

}
