var toggler = function(){
  this.init();
};

toggler.prototype = {
  init: function(){
    var $this = this;
    if(!this._setVars()) return;
    this._setEvents();
  },

  _setVars: function() {
    this._menu = $('.mainNav');
    this._menuToggler = $('.mainNav--toggle');
    this._menuContent = this._menu.find('.menu');

    return true;
  },

  _toggleClass: function() {
    this._menuContent.toggleClass('visible');
  },

  _setEvents: function() {
    var $this = this;
    this._menuToggler.on('click', function(e) {
      $this._toggleClass();
    })
  }
};
