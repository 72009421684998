var popup = function() {
		this.init();
};

popup.prototype = {

		init: function() {
				var $this = this;
				if(!this._setVars()) return;
				this._setEvents();
		},

		_setVars: function() {

			if($.cookie('popup1')) return false;

			this._popUp = $('.sPopup');
			if(this._popUp.length == 0) return false;

			this._popUpSubmit = this._popUp.find('.sPopup__submit');

			this._popUpButtonClose = this._popUp.find('.sPopup__die');

			// helpers
			this._timeout = this._popUp.data('time');
			this._timeout = this._timeout * 1000;
			return true;
		},

		_setEvents: function(){
			var $this = this;
			
			setTimeout(function(){
				$('.sPopup__mask').css("display", "block");
				$this._popUp.addClass('visible');
			}, $this._timeout);

			this._popUpSubmit.on('click', function (e) {

				$this._popUp.removeClass('visible');
				$('.sPopup__mask').css("display", "none");
				$.cookie('popup1', 'checked', { expires: 7, path: '/' });

			});

			this._popUpButtonClose.on('click', function(e){
				
				e.preventDefault();
				$this._popUp.removeClass('visible');
				$('.sPopup__mask').css("display", "none");
				$.cookie('popup1', 'close', { expires: 7, path: '/' });

			});

		}
}