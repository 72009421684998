var fadeInContentNews = function(){
  this.init();
}

fadeInContentNews.prototype = {
  init: function(){
    var $this = this;
    if(!this._setVars()) return;
    this._setEvents();
  },

  _setVars: function() {
    this._news = $('.container-box');

    return true;
  },

  _fadeInBox: function(item){
    $(item).each( function(i){

        var top_of_object = $(this).offset().top;
        var bottom_of_window = $(window).scrollTop() + $(window).height() - 200;

        /* If the object is completely visible in the window, fade it it */
        if( bottom_of_window > top_of_object ){

            $(this).addClass('visible');

        }

    });
  },

  _setEvents: function(){
    var $this = this;
    this._fadeInBox($this._news);
    $(window).scroll(function(){
      $this._fadeInBox($this._news);
    });
  }
}
