/* show file name in input when upload */

$('input[type=file]').change(function () {
		var patch = 	$(this).val();
		var filename = patch.replace(/^.*\\/, "");
		$(this).parent().append("<span class='jsFileName'>" + filename + "</span>");
});
$('input[type=file]').on('click', function(){
		$(this).next('.jsFileName').remove();
});
