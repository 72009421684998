
var wpcf7Elm = document.querySelector( '.wpcf7' );

if (wpcf7Elm) {
	// wpcf7Elm.addEventListener('wpcf7mailsent', function( event ) {
	// 	alert("Formularz został przesłany poprawnie.\nNasz konsultant skontaktuje się z Państwem w ciągu 24h.");
	// }, false );

	wpcf7Elm.addEventListener('wpcf7mailfailed', function( event ) {
		alert("Formularz NIE został przesłany poprawnie.\nProsimy o spróbowanie ponownie lub kontakt inną drogą.");
	}, false );
}
