var accordion = function(){
  this.init();
}

accordion.prototype = {
  init: function(){
    var $this = this;
    if(!this._setVars()) return;
    this._setEvents();
  },

  _setVars: function(){
    this._accordion = $('.accordion');
    this._accordionItem = this._accordion.find('.accordion__item');
    this._accordionHead = this._accordion.find('.accordion__header');
    this._accordionDetails = this._accordionHead.find('.accordion__details');
    this._accordionForm = this._accordion.find('.btn--green');
    this._accordionFormClose = this._accordion.find('.btn--black');

    return true;
  },

  _toggleClass: function(item){

    item.slideToggle("slow");
    item.parent('.accordion__item').toggleClass('active');

  },

  _toggleClassWCAG: function(item){

    item.children('.accordion__details').slideToggle("slow", function(){});
    item.toggleClass('active');

  },

  _showForm: function(item){
    var $this = this;

    item.prev($this._accordionForm).addClass('hidden');
    item.slideToggle("slow", function(){});
    item.children('.btn--black').animate({top: "-25px"});

  },

  _hideForm: function(item){

    item.prev('.btn--green').removeClass('hidden');
    item.slideToggle("slow", function(){});
    item.children('.btn--black').animate({top: "0"});

  },

  _setEvents: function(){
    var $this = this;

    this._accordionDetails.slideUp();

    this._accordionHead.on('click', function(e){
        $this._toggleClass($(this).next('.accordion__details'));
    });

    this._accordionItem.on('keydown', function(e){
      if(e.keyCode == 13){
        if($(this).find('.btn--green').is(":focus")){
          return false;
        }
        else{
          $this._toggleClassWCAG($(this));
        }
      }
    });

    this._accordionForm.on('click', function(e){
        $this._showForm($(this).next('.wrapper--colorBcg'));
    });

    /* for wcag */
    this._accordionForm.on('keydown', function(e){
        $this._showForm($(this).next('.wrapper--colorBcg'));
    });

    this._accordionFormClose.on('click', function(e){
      $this._hideForm($(this).parent('.wrapper--colorBcg'));
    });

  }
}
