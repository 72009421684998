var locationsMap = function(){
	this.init();
};

locationsMap.prototype = {

	init: function() {

		if (!$('.locationsMap').length)
			return;

		var $this = this;

		if (!this._setVars())
			return;

		this._setEvents();

	},

	_getMaxClosest: function() {

		var $this = this;
		var width = $(window).width();

		if (width > 1024)
			$this.maxClosest = 4;
		else if (width > 768)
			$this.maxClosest = 3;
		else if (width > 600)
			$this.maxClosest = 2;
		else
			$this.maxClosest = 1;

		$this.currentClosest = 0;

		$('.map__places .map__places__item').css('transform', 'translateY(0)');

	},

	_setVars: function() {

		var $this = this;
		var url   = window.location.search.substring(1).split('=');

		$this.mapFiltersPlace = $('.map__filters__select--place');
		$this.mapFiltersGeo = $('.map__filters__select--geo');

		if ($.cookie('map_filters_place')) {

			$this.mapFiltersPlace.find('li[data-value=' + $.cookie('map_filters_place') + ']')
				.addClass('selected')
				.css('order', '-1')
				.siblings()
					.removeClass('selected')
					.css('order', 'initial');

			$this.filterPlace = $.cookie('map_filters_place');

		} else {

			$this.filterPlace = $this.mapFiltersPlace.find('li.selected').attr('data-value');

		}

		$this.filterGeo = $this.mapFiltersGeo.find('li.selected').attr('data-value');

		$this.json = [];
		$this.gmarkers = [];
		$this.markerCluster = [];
		$this.locations = [];
		$this.placeLocations = [];
		$this.centerMap = [];
		$this.closest = [];
		$this.maxClosest = 0;
		$this.currentClosest = 0;
		$this.autocomplete = {};
		$this.placeCenter = (url[0]) ? url[1] : false;

		$this.imagesSrc = 'wp-content/themes/sgb/images/map/';

		if (!$('body').hasClass('home'))
			$this.imagesSrc = '../' + $this.imagesSrc;


		$this._getMaxClosest();

		return true;

	},

	_itemsCount: function(json) {

		if (!$('body').hasClass('home'))
			return;

		var bankCount = 0;
		var atmCount  = 0;

		$.each(json, function(key, data) {

			if (data.type == 'bank')
				bankCount++;
			if (data.type == 'atm')
				atmCount++;

		});

		$('span[data-id=bank_count]').html(bankCount);
		$('span[data-id=atm_count]').html(atmCount);

	},

	_getJSON: function() {

		var $this = this;

		$.getJSON($('.logo').parent().attr('href') + 'wp-content/uploads/json_locations/markers_list.json', function(json) {

			$this.json = json;
			$this._centerMap();
			$this._itemsCount(json);

			var $lat = $this._tempLat = $this.json[0].location.lat;
			var $lng = $this._tempLat = $this.json[0].location.lng;

			//console.log($lat + '/' + $lng);
			var myLatlng = new google.maps.LatLng($lat, $lng);
			$this.map.setCenter(myLatlng);

		});

	},

	_initMap: function() {

		var $this = this;

		$this.map = new google.maps.Map(document.getElementById('map'), {
			zoom: 14,
			maxZoom: 17,
			center: {lat: 50.3400727, lng: 23.5188666},
			styles:
			[{"featureType": "landscape.man_made","elementType":"all","stylers":[{"color":"#faf5ed"},{"lightness":"0"},{"gamma":"1"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#bae5a6"}]},{"featureType":"road","elementType":"all","stylers":[{"weight":"1.00"},{"gamma":"1.8"},{"saturation":"0"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"hue":"#ffb200"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"lightness":"0"},{"gamma":"1"}]},{"featureType":"transit.station.airport","elementType":"all","stylers":[{"hue":"#b000ff"},{"saturation":"23"},{"lightness":"-4"},{"gamma":"0.80"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#a0daf2"}]},{"featureType": "poi","elementType": "labels", "stylers": [{"visibility": "off"}]}],
			mapTypeControl: false,
			streetViewControl: false,
			scrollwheel: false
		});

		if (!$('body').hasClass('home')) {

			$('.locationsMap').append('<div class="infoWindow" style="display: none;"><div class="infoWindow__inner"><div class="infoWindow__head"><div class="infoWindow__city"></div><div class="infoWindow__title"></div><div class="infoWindow__subtitle"></div></div><div class="infoWindow__details"><div class="infoWindow__address"></div><div class="infoWindow__phone"></div><div class="infoWindow__hours"></div><a href="#" target="_blank" class="infoWindow__url btn btn--transparent">Wyznacz trasę</a></div><div class="infoWindow__close"></div></div></div>');

		}

		$this._getJSON();

	},

	_centerMap: function() {

		console.log('center map');

		var $this = this;
		var pos   = [];

		if ((window.location.protocol == 'https:') && navigator.geolocation) {

			navigator.geolocation.getCurrentPosition(function(position) {

				pos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};

				$this.centerMap = pos;
				$this.map.setCenter(pos);
				//$this._addMarkers();

			});

		} else {

			//$this._addMarkers();

		}

		$this._addMarkers();

	},

	_removeMarkers: function() {

		var $this = this;

		if ($this.gmarkers.length == 0)
			return;

		for (var i = 0; i < $this.gmarkers.length; i++ ) {

			$this.gmarkers[i].setMap(null);

		}

		$this.gmarkers  = [];
		$this.locations = [];
		$this.placeLocations = [];
		$this.markerCluster.clearMarkers();

	},

	_showInfoWindow: function(marker, placeType) {

		var $this = this;
		var data  = (placeType == 'bank')?$this.placeLocations[marker]:$this.locations[marker];
		var address = data.street + ', ' + data.postal_code + ' ' + data.city;
			address = address.replace(' ', '+');

		$('.infoWindow .infoWindow__city').html(data.city);
		$('.infoWindow .infoWindow__title').html(data.title);
		$('.infoWindow .infoWindow__subtitle').html(data.subtitle);

		if (data.phone) {

			$('.infoWindow .infoWindow__phone')
				.html('tel. ' + data.phone)
				.show();

		} else {

			$('.infoWindow .infoWindow__phone').hide();

		}

        if (data.email) {

            $('.infoWindow .infoWindow__mail')
                .html('<a href="mailto:' + data.email + '" class="link">' + data.email + '</a>')
                .show();

        } else {

            $('.infoWindow .infoWindow__mail').hide();

        }

		if (data.hours) {

			$('.infoWindow .infoWindow__hours')
				.html(data.hours)
				.show();

		} else {

			$('.infoWindow .infoWindow__hours').hide();

		}

		$('.infoWindow a').attr('href', 'https://maps.google.com?saddr=Current+Location&daddr=' + address);

		$('.infoWindow').fadeIn();

		for (var i = 0; i < $this.gmarkers.length; i++) {

			$this.gmarkers[i].setIcon($this.imagesSrc + 'marker.png');

		}

		$this.gmarkers[marker].setIcon($this.imagesSrc + 'marker_hover.png');

	},

	_addMarkers: function() {

		var $this = this;
		var id = 0;

		$.each($this.json, function(key, data) {

			/////////////////
			if (data.type == 'bank') {
				//console.log(data.type);
				$this.placeLocations.push(data);
			}

			if (($this.filterPlace == 'all') || ($this.filterPlace == data.type)) {

				if (parseFloat(data.location.lat) && parseFloat(data.location.lng) && data.location.lat && data.location.lng) {

					if (parseFloat(data.location.lat) < parseFloat(data.location.lng)) {
						console.log(data);
					}

					var myLatlng = new google.maps.LatLng(data.location.lat, data.location.lng);
					var marker = new google.maps.Marker({
						position: myLatlng,
						title: data.title,
						icon: $this.imagesSrc + 'marker.png'
					});


					//console.log(marker);


					marker.setMap($this.map);

					marker.addListener('click', function() {

						$this.map.setCenter(marker.getPosition());
						if ($('.infoWindow').length > 0) {

							$this._showInfoWindow(data.position, '');

						} else {

							window.location.href = 'placowki-i-bankomaty?place_id=' + data.id;

						}

					});

					data.position = id;

					if (($this.placeCenter !== false) && ($this.placeCenter == data.id))
						$this.placeCenter = id;

					$this.gmarkers.push(marker);
					$this.locations.push(data);

					id++;

				} else {
					console.log(data);
				}

			}

		});

		if ($this.placeCenter !== false) {

			var id = $this.placeCenter;

			var pos = {
				lat: $this.locations[id].location.lat,
				lng: $this.locations[id].location.lng
			};

			$this.centerMap = pos;
			$this.map.setCenter(pos);
			$this._showInfoWindow(id, '');

			$this.placeCenter = false;

		}

		$this.markerCluster = new MarkerClusterer($this.map, $this.gmarkers, {
			imagePath: $this.imagesSrc + 'group'
		});

		$this._findClosestLocations();

	},

	_updateMarkers: function() {

		var $this = this;

		$this._removeMarkers();

		$this.filterPlace = $this.mapFiltersPlace.find('li.selected').attr('data-value');
		$this.filterGeo   = $this.mapFiltersGeo.find('li.selected').attr('data-value');

		if ($this.filterGeo == 'current') {

			$this._centerMap();

		} else {

			$this._addMarkers();

		}

		$this._saveSettings();

	},

	_findClosestLocations: function() {

		var $this = this;
		var pi = Math.PI;
		var R = 6371;
		var distances = [];
		var closest = 0;

		if ($this.locations.length == 0) {

			$('.map__wrapper .wrapper').hide();
			return;

		}

		for (i = 0; i < $this.locations.length; i++) {

			var lat2 = $this.locations[i]['location']['lat'];
			var lon2 = $this.locations[i]['location']['lng'];

			var chLat = lat2 - $this.centerMap.lat;
			var chLon = lon2 - $this.centerMap.lng;

			var dLat = chLat * (pi / 180);
			var dLon = chLon * (pi / 180);

			var rLat1 = $this.centerMap.lat * (pi / 180);
			var rLat2 = lat2 * (pi / 180);

			var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(rLat1) * Math.cos(rLat2);
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
			var d = R * c;

			distances.push({'distance' : d, 'location' : i});

		}

		distances.sort(sortDistance);

		function sortDistance(a, b) {

			if (a['distance'] === b['distance']) {

				return 0;

			} else {

				return (a['distance'] < b['distance']) ? -1 : 1;

			}

		}

		for (i = 0; i < distances.length; i++) {

			distances[i] = $this.locations[distances[i]['location']];

		}

		$this.closest = distances;

		if ($('body').hasClass('home')) {

			$('.map__wrapper .wrapper').show();
			$('.map__places .map__places__item').remove();

			for (i = 0; i < 100; i++) {

				if (!$this.closest[i])
					break;

				var data = $this.closest[i];

				$('.map__places').append('<div class="map__places__item"><a href="placowki-i-bankomaty?place_id=' + data.id + '" title="Kliknij aby przejść do wybranej placówki"><p>' + data.title + '</p><p>' + data.subtitle + '</p><p>' + data.street + ', ' + data.postal_code + ' ' + data.city + '</p></a></div>');

			}

		} else if ($('body').hasClass('page-template-page-placowki')) {

			$('.map__places > div').remove();

			for (i = 0; i < 32; i++) {

				if (!$this.closest[i])
					break;

				var data = $this.closest[i];

				if(data.subtitle == null){
				    data.subtitle = '';
                }
				var box = '<div class="container-small visible col-3"><a href="#" title="pokaż na mapie" class="showOnMap" data-id="' + data.id + '"><div class="container-small__header"><span class="search__city">' + data.city + '</span><span class="search__details">' + data.title + '<span>' + data.subtitle + '</span></span><span class="search__adress">' + data.street + '</span>';

				if (data.hours)
					box += '<span class="search__hours">' + data.hours + '</span></span>';

				box += '</div><div class="container__subsection"><span class="container-small__btn">pokaż na mapie</span></div></a></div>';

				$('.map__places').append(box);

			}

		}

	},

	_autocompleteGeolocate: function() {

		var $this = this;

		if (navigator.geolocation) {

			navigator.geolocation.getCurrentPosition(function(position) {

				var geolocation = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};

				var circle = new google.maps.Circle({
					center: geolocation,
					radius: position.coords.accuracy
				});

				$this.autocomplete.setBounds(circle.getBounds());

			});

		}

	},

	_initAutocomplete: function() {

		var $this = this;

		if ($('#autocomplete').length == 0)
			return;

		$this.autocomplete = new google.maps.places.Autocomplete(
			(document.getElementById('autocomplete')),
			{types: ['geocode']}
		);

		$this._autocompleteGeolocate();

		$this.autocomplete.addListener('place_changed', function() {

			$this.mapFiltersGeo.removeClass('jsScrollable');

			$this.mapFiltersGeo.find('.map__filter:first-child')
				.removeClass('selected')
				.css('order', 'initial');

			$this.mapFiltersGeo.find('.map__filter:last-child')
				.addClass('selected')
				.css('order', '-1');

			var place = this.getPlace();

			$this.centerMap = {
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng()
			};

			$this.map.setCenter($this.centerMap);

			$this._findClosestLocations();

		});

	},

	_saveSettings: function() {

		var $this = this;

		$.cookie('map_filters_place', $this.filterPlace, { path: '/' });

	},

	_showOnMap: function(id) {

		var $this = this;

		// for (i = 0; i < $this.locations.length; i++) {
		// 	if ($this.locations[i]['id'] == id) {
		// 		id = i;
		// 		break;
		// 	}
		// }

		// var pos = new google.maps.LatLng($this.locations[id].location.lat, $this.locations[id].location.lng);

		for (i = 0; i < $this.placeLocations.length; i++) {
			if ($this.placeLocations[i]['id'] == id) {
				id = i;
				break;
			}
		}

		var pos = new google.maps.LatLng($this.placeLocations[id].location.lat, $this.placeLocations[id].location.lng);


		$this.centerMap = pos;
		$this.map.setCenter(pos);
		$this._showInfoWindow(id, 'bank');

		$('html, body').animate({
			scrollTop: $('#map').offset().top
		}, 500);

	},

	_setEvents: function() {

		var $this = this;

		$(document).ready(function() {

			$this._initMap();
			$this._initAutocomplete();

		});

		$(document).on('click', '.infoWindow .infoWindow__close', function() {

			$(this).closest('.infoWindow').fadeOut();

			for (var i = 0; i < $this.gmarkers.length; i++) {

				$this.gmarkers[i].setIcon($this.imagesSrc + 'marker.png');

			}

		});

		$(window).resize(function() {

			$this._getMaxClosest();

		});

		$('.map .btn').click(function() {

			$this.currentClosest++;

			if ($this.currentClosest < ($('.map__places .map__places__item').length - $this.maxClosest)) {

				$('.map__places .map__places__item').css('transform', 'translateY(-' + ($this.currentClosest * 100) + '%)');

			}

		});

		$('.map__filters__select').click(function(event) {

			event.preventDefault();

			$(this).siblings('.jsScrollable').removeClass('jsScrollable');
			$(this).toggleClass('jsScrollable');

		});

		$('.map__filters__select input').click(function(event) {

			if ($(this).closest('.map__filters__select').hasClass('jsScrollable')) {

				event.stopPropagation();

			}

		});

		$('.jsFilterMaps li a').click(function(event) {

			event.preventDefault();

			if ($(this).closest('.jsFilterMaps').hasClass('jsScrollable') || $(this).closest('.jsFilterMaps').hasClass('jsScrollableOpen')) {

				$(this).parent().siblings()
					.removeClass('selected')
					.css('order', 'initial');

				$(this).parent()
					.addClass('selected')
					.css('order', '-1');

				$(this).parent().siblings().find('input').val('');

				$this._updateMarkers();

			}

		});

		$(document).on('click', '.showOnMap', function(event) {

			event.preventDefault();

			$this._showOnMap($(this).attr('data-id'));

		});

	}

};
