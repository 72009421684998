var rotator = function(){
  this.init();
};

rotator.prototype = {
  init: function(){
    var $this = this;
    if(!this._setVars()) return;
    this._setEvents();
  },

  _setVars: function() {
    this._rotator = $('.rotator');

    return true;
  },

	_switchSide: function(item){
		item.toggleClass('flipped');
	},

	_setEvents: function() {
		var $this = this;

		this._rotator.on('click', function(e){
      if(!$(e.target).closest('a').length){
        $this._switchSide($(this));
      }
		})
	}
}
