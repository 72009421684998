var cookies = function() {

	this.init();

};

cookies.prototype = {

	init: function() {

		var $this = this;

		if (!this._setVars())
			return;

		this._setEvents();

	},

	_setVars: function() {

		var $this = this;

		if ($('.cookiesBox').length == 0)
			return false;

		$this.cookie       = $('.cookiesBox');
		$this.cookieCookie = $this.cookie.find('.button');

		return true;

	},

	_setEvents: function() {

		var $this = this;

		$this.cookieCookie.click(function(event) {

			event.preventDefault();

			$this.cookie.addClass('removed');

			setTimeout(function() {

				$this.cookie.remove();

			}, 500);

			$.cookie('cookies', 1, { expires: 30, path: '/' });

		});

	}

}