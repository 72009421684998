var core = function() { this.init(); };

core.prototype = {
	init: function() {
			this._run();
	},

	_run: function() {
			document.toggler 						= new toggler();
			document.slider 						= new slider();
			document.counter 						= new counter();
			document.exchangesBoxV2				= new exchangesBoxV2();
			document.fadeInContent 				= new fadeInContent();
			document.fadeInContentNews 		= new fadeInContentNews();
			document.scrollTo 					= new scrollTo();
			document.ajaxPostLoader 			= new ajaxPostLoader();
			document.locationsMap 				= new locationsMap();
			document.contactMap 					= new contactMap();
			document.showActiveItem 			= new showActiveItem();
			document.rotator 						= new rotator();
			document.calculator 					= new calculator();
			document.accordion 					= new accordion();
			document.offerScroll 				= new offerScroll();
			document.slideHeader 				= new slideHeader();
			document.cookieLaw 					= new cookieLaw();
			document.poll 							= new poll();
			document.popup							= new popup();
			document.cookies						= new cookies();
			document.validationContact 		= new validationContact();

			// special
			document.sLoginMobile 				= new sLoginMobile();

	}
};

$(document).ready(function() { new core(); });
