var cookieLaw = function(){

	this.init();

};

cookieLaw.prototype = {

	init: function(){

		var $this = this;

		if (!this._setVars())
			return;

		this._setEvents();

	},

	_setVars: function(){

		this._cookieBox = $('.jsCookieInfo');
		this._sayYesToCookie = this._cookieBox.find('.jsCookieInfo--accept');

		return true;

	},

	_setEvents: function(){

		var $this = this;

		if($.cookie('cookieLaw') === 'yes'){
			return;
		}
		else{
			$this._cookieBox.css("display", "block");
		}

		this._sayYesToCookie.on('click', function(){

			$.cookie('cookieLaw', 'yes', { expires: 15000, path: '/' });
			$this._cookieBox.css("display", "none");

		});

	}

}
